const dataURLtoFile = (dataurl, filename) => {
    let arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
}

const processFromBase64toFile = (base64, filename) => {
    let file = dataURLtoFile(base64, filename);
    let container = new DataTransfer();
    container.items.add(file);
    return container.files;
}

export { dataURLtoFile };
export default processFromBase64toFile;