import './bootstrap';
import Alpine from 'alpinejs';
import collapse from '@alpinejs/collapse';
import '@splidejs/splide/css';
import Splide from '@splidejs/splide';
import 'cropperjs/dist/cropper.css';
import Cropper from 'cropperjs';
import { extendsObj } from "./utils/extendsObj";
import { isEmpty } from "./utils/isEmpty";
import TomSelect from "tom-select";
import 'tom-select/dist/css/tom-select.css';
/**
 * Sweetalert2
 */
import Swal from "sweetalert2";
/**
 * Tippy.js
 */
import tippy from "tippy.js";
import tippyHeadless from "tippy.js/headless";
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import processFromBase64toFile from "./utils/processFromBase64toFile";
import convertTZ from "./utils/convertTZ";
import zeroPad from "./utils/zeroPad";

window.Cropper = Cropper;

Alpine.plugin( collapse );

let livewireComponent,
    $wire;

document.addEventListener( 'DOMContentLoaded', () => {
  let livewireElement = document.querySelector( '[wire\\:id]' );
  livewireComponent = $wire = livewire.find( livewireElement.getAttribute( 'wire:id' ) );
} );

Alpine.data( 'showPassword', ( { showPassword, type } ) => (
  {
    show: false,
    toggle() {
      this.show = !this.show;
    },
    inputType: {
      ':type'() {
        if ( showPassword ) {
          return this.show ? 'text' : 'password';
        }
        return type;
      }
    }
  }
) );

Alpine.data( 'searchSelection', ( { withSearch, options } ) => (
  {
    instance: null,
    init() {
      if ( withSearch ) {

        const defaults = {
          create          : true,
          sortField       : {
            field    : 'text',
            direction: 'asc'
          },
          plugins         : {
            'dropdown_input': {}
          },
          hideSelected    : true,
          closeAfterSelect: true,
          maxItems        : 1,
          placeholder     : 'Selecciona una opción',
          render          : {
            option_create: function ( data, escape ) {
              return '<div class="create">Agregar <strong>' + escape( data.input ) + '</strong>&hellip;</div>';
            },
            no_results   : function ( data, escape ) {
              return '<div class="no-results">No se encontraron resultados</div>';
            },
          }
          /*onChange: (value) => {
           this.$dispatch('change', value);
           }*/
        }

        const opts = extendsObj( {}, defaults, options );

        if ( !this.$refs.select.tomselect ) {
          this.instance = new TomSelect( this.$refs.select, opts );
        }

      }
    }
  }
) );

window.carousels = [];

Alpine.data( 'carousel', ( options = {} ) => (
  {
    instance: null,
    init() {

      const defaults = {
        rewind     : true,
        rewindSpeed: 1000,
        perPage    : 3,
        gap        : '1rem',
        easing     : 'cubic-bezier(.645,.045,.355,1)',
        pagination : false,
        arrows     : false,
      }

      const opts = extendsObj( {}, defaults, options );

      this.instance = new Splide( this.$refs.splideIdentifier, opts ).mount();

    }
  }
) );

Alpine.data( 'datePickr', ( options = {} ) => (
  {
    instance: null,
    init() {

      const defaults = {
        locales: 'es',
      }

      const opts = extendsObj( {}, defaults, options );
      this.instance = flatpickr( this.$refs.fpickr, opts );

    }
  }
) );

Alpine.data( 'cropper', ( wireName, options = {} ) => (
  {
    instance: null,
    options : {},
    modeEdit: false,
    canvas  : null,
    wireName,
    init() {

      const defaults = {
        aspectRatio: 1,
        viewMode   : 1,
      }

      this.options = extendsObj( {}, defaults, options );

    },
    showButton() {
      return this.$refs.imageInput.files.length;
    },
    edit: {
      '@click'() {
        if ( this.modeEdit ) {
          this.canvas = this.instance.getCroppedCanvas( {
            width : 700,
            height: 700,
          } );
          this.canvas.toBlob( ( blob ) => {
            let url = URL.createObjectURL( blob );
            let reader = new FileReader();
            reader.readAsDataURL( blob );
            reader.onloadend = () => {
              let base64data = reader.result;
              // this.$refs.cropperIdentifier.src = base64data;
              let files = processFromBase64toFile( base64data, this.$refs.imageInput.files[ 0 ].name );
              this.$refs.imageInput.files = files;

              $wire.upload( 'image', files[ 0 ], ( uploadedFilename ) => {
                console.log( 'success' );
              }, ( error ) => {
                console.log( 'error' );
              } );
              this.instance.destroy();
              this.modeEdit = false;
            };
          } );
        } else {
          this.instance = new Cropper( this.$refs.cropperIdentifier, this.options );
          this.modeEdit = true;
        }
      },
      'x-text'() {
        return this.modeEdit ? 'Recortar' : 'Ajustar';
      }
    },
  }
) );

Livewire.on( 'message-resend-countdown', ( dateString ) => {

  const elementMustVerifyAccount = document.querySelector( '[wire\\:init="initialFancyCountdown"]' );
  const wireId = elementMustVerifyAccount.getAttribute( 'wire:id' );
  const livewireComponent = livewire.find( wireId );
  const dateLastMessageSent = new Date( dateString );
  const timeLeftResendMessage = document.querySelector( '#time-left-resend-message' );

  let timer = setInterval( () => {
    const dateNow = convertTZ( new Date(), 'America/Mexico_City' );
    const diff = dateLastMessageSent - dateNow;
    const seconds = Math.floor( (
      diff / 1000
    ) % 60 );
    const minutes = Math.floor( (
      diff / 1000 / 60
    ) % 60 );

    if ( diff <= 0 ) {
      clearInterval( timer );
      livewireComponent.emit( 'refresh' )
    }

    timeLeftResendMessage.textContent = `${minutes}:${zeroPad( seconds, 2 )}`;

  }, 1000 );

} );

Livewire.on( 're-renderCarousel', ( id = null ) => {
  let carousel = document.querySelector( id || '[x-data^="carousel"]' );
  if ( carousel ) {
    Alpine.initTree( carousel );
  }
} );

window.carouselCart = null;

Livewire.on( 're-renderCarouselCart', ( showProduct ) => {

  let carousel = document.querySelector( '#carouselCart' );
  const defaults = {
    rewind     : true,
    rewindSpeed: 1000,
    perPage    : 1,
    gap        : '1rem',
    easing     : 'cubic-bezier(.645,.045,.355,1)',
    pagination : false,
    arrows     : false,
  };
  let opts = JSON.parse( carousel.dataset.opts )
  let options = extendsObj( {}, defaults, opts );

  if ( carousel ) {

    if ( showProduct ) {
      setTimeout( () => {
        window.carouselCart?.destroy();
        window.carouselCart = new Splide( carousel, options ).mount();
      }, 200 );
    } else {
      window.carouselCart?.destroy();
      window.carouselCart = new Splide( carousel, options ).mount();
    }

  }

} );

Livewire.on( 're-renderSearchSelection', () => {
  let carousel = document.querySelectorAll( '[x-data^="searchSelection"]' );
  carousel.forEach( ( item ) => {
    Alpine.initTree( item );
  } );
} );

document.addEventListener( 'alpine:init', () => {
  Alpine.data( 'splide', () => (
    {
      instance: null,
      init() {
        this.instance = new Splide( this.$refs.splidelist, {
          fixedHeight: 350,
          autoplay   : true,
          speed      : 500,
          rewind     : true,
          rewindSpeed: 1000,
          interval   : 7000,
          easing     : 'cubic-bezier(.645,.045,.355,1)',
        } ).mount();

        let items = this.$refs.splidelist.querySelectorAll( 'ul.splide__list > li ~ li' );
        items.forEach( ( el ) => {
          el.style.display = 'initial';
        } );
      }
    }
  ) )
} );

Alpine.data( 'editingData', ( editing = false ) => (
  {
    editing,
    changeColorIcon: {
      ':class'() {
        return this.editing ? 'text-red-500' : 'text-gray-500';
      }
    },
    switchToEditing() {
      this.editing = !this.editing;
    }
  }
) );

Alpine.data( 'rating', ( initialRating, color, editable ) => (
  {
    rating     : initialRating,
    hoverRating: initialRating,
    classCondition( index ) {
      return {
        [ color ]                         : this.hoverRating >= index,
        'text-gray-300 dark:text-gray-500': this.hoverRating < index
      }
    },
    ratingIcon: {
      '@click'() {
        if ( editable ) {
          this.rating = this.$el.dataset.rating;
          this.$refs.ratingInput.value = this.rating;
          this.$refs.ratingInput.dispatchEvent( new Event( 'input' ) );
        }
      },
      '@mouseenter'() {
        if ( editable ) {
          this.hoverRating = this.$el.dataset.rating;
        }
      },
      '@mouseleave'() {
        if ( editable ) {
          this.hoverRating = this.rating;
        }
      },
    },
  }
) );

Alpine.data( 'dropdown', () => (
  {
    open: false,
    toggle() {
      this.open = !this.open;
    },
    component: {
      '@click.away'() {
        this.open = false;
      },
      '@close.stop'() {
        this.open = false;
      }
    },
    content  : {
      'x-show'() {
        return this.open;
      },
      '@click'() {
        this.open = false;
      },
      'x-transition:enter'() {
        return 'transition ease-out duration-200';
      },
      'x-transition:enter-start'() {
        return 'transform opacity-0 scale-95';
      },
      'x-transition:enter-end'() {
        return 'transform opacity-100 scale-100';
      },
      'x-transition:leave'() {
        return 'transition ease-in duration-75';
      },
      'x-transition:leave-start'() {
        return 'transform opacity-100 scale-100';
      },
      'x-transition:leave-end'() {
        return 'transform opacity-0 scale-95';
      }
    }
  }
) );

window.Alpine = Alpine;

Alpine.start();

window.Swal = Swal;

Livewire.on( 'Swal', (
  title                              = false, text = false, icon = 'info',
  timer                              = false, toast               = false,
  showConfirmButton = true, position = false
) => {
  Swal.fire( {
    title,
    text,
    icon,
    timer,
    toast,
    showConfirmButton,
    position
  } );
} );

window.tippy = tippy;
window.tippyInstances = null;
window.tippyFilters = [];

document.addEventListener( 'DOMContentLoaded', () => {

  const verifyMercaditoInputSelect = () => {

    const mercaditoInputs = document.querySelectorAll( '.mercadito-input, .mercadito-select' );

    mercaditoInputs.forEach( ( el ) => {
      el.classList.toggle( 'active', !isEmpty( el.value ) );
      let fnListener = ( e ) => {
        e.target.classList.toggle( 'active', !isEmpty( e.target.value ) );
      };
      el.addEventListener( el.tagName === 'INPUT' ? 'keyup' : 'change', fnListener );
    } );

  }

  verifyMercaditoInputSelect();

  /**
   * Cart Total
   */
  Livewire.on( 'verifyMercaditoInputSelect', () => {
    verifyMercaditoInputSelect();
  } );

  const templateAccount = document.querySelector( '#template-account' );

  let triggerAccount = document.querySelector( '#trigger-account' );

  tippyHeadless( triggerAccount, {
    content    : templateAccount.innerHTML,
    allowHTML  : true,
    placement  : 'bottom',
    interactive: true,
    arrow      : true,
    maxWidth   : 550,
    appendTo   : document.body,
    render( instance ) {

      const popper = document.createElement( 'div' );
      const box = document.createElement( 'div' );

      box.className = 'account-box';
      box.innerHTML = instance.props.content;

      popper.appendChild( box );

      return {
        popper,
      };

    },
  } );

  tippyInstances = tippy( '[data-tippy-content]' );

  /**
   * Pruebas de tippy y Livewire
   */

  const tippyFiltersInit = () => {

    let filterTemplate = document.querySelectorAll( '.template-filtro' ),
        activeFilter   = '';

    filterTemplate.forEach( ( el ) => {
      tippyFilters.push( tippy( document.querySelector( el.dataset.selector ), {
        content    : el.content.cloneNode( true ),
        allowHTML  : true,
        trigger    : 'click',
        interactive: true,
        theme      : 'light',
        /*onShow( instance, event ) {
         console.log( 'onShow' )
         console.log( instance );
         }*/
        /*onTrigger(instance, event) {

         let { filter } = instance.popper.querySelector( '[data-filter]' ).dataset;

         if ( ! isEmptyNull( activeFilter ) ) {
         if ( filter !== activeFilter.filter ) {
         let newTippyFilters = tippyFilters.filter( ( tippy ) => instance !== tippy );
         console.log( newTippyFilters );
         console.log( instance );
         }
         } else {
         activeFilter = {
         filter,
         instance
         };
         }
         },*/
      } ) );
      // el.innerHTML = '';
    } );

  }

  tippyFiltersInit();

  Livewire.on( 'refreshTippyFilters', () => {
    console.log( 'refreshTippyFilters' );
    tippyFilters?.forEach( instance => {
      instance.destroy();
    } );
    tippyFiltersInit();
  } );

  Livewire.on( 'refreshTippyJs', () => {
    tippyInstances?.forEach( instance => {
      instance.destroy();
    } );
    tippyInstances = tippy( '[data-tippy-content]' );
  } );

  Livewire.on( 'fixedSelect', ( ...selectors ) => {
    selectors.forEach( ( selector ) => {
      let select = document.querySelector( selector );
      if ( select ) {
        select.firstElementChild.selected = true;
      }
    } );
  } );

  Livewire.on( 'logoUpdated', ( url ) => {
    document.querySelector( '.logo' ).src = url;
  } );

  /**
   * Cart Total
   */
  Livewire.on( 'cartUpdated', ( total ) => {
    document.querySelector( '#cart-total' ).innerHTML = '$' + total;
  } );

  /**
   * Scroll to product
   */
  Livewire.on( 'scrollProduct', ( product_id ) => {
    const offsetTop = document.querySelector( product_id ).offsetTop;

    scroll( {
      top     : offsetTop,
      behavior: 'smooth'
    } );
  } );

  let categories = document.querySelectorAll( '.categoriesWrapper .category' );

  categories.forEach( ( category ) => {

    category.addEventListener( 'mouseenter', () => {
      if ( !category.classList.contains( 'active' ) && !category.classList.contains( 'category-selected' ) ) {
        category.classList.add( 'active' );
        let categoryColor = category.dataset.color;
        category.style.backgroundColor = categoryColor;
        document.documentElement.style.setProperty( '--category-color', categoryColor );
      }
    } );

    category.addEventListener( 'mouseleave', () => {
      if ( category.classList.contains( 'active' ) && !category.classList.contains( 'category-selected' ) ) {
        category.classList.remove( 'active' );
        category.removeAttribute( 'style' );
      }
    } );

  } );

  let adminUserMenu = document.querySelectorAll( '#admin-user-menu a' );

  adminUserMenu.forEach( ( item ) => {

    item.addEventListener( 'mouseenter', () => {
      if ( !item.classList.contains( 'active' ) ) {
        let { color } = item.dataset;
        item.style.backgroundColor = color;
        item.style.color = '#fff';
      }
    } );

    item.addEventListener( 'mouseleave', () => {
      if ( !item.classList.contains( 'active' ) ) {
        let { color } = item.dataset;
        item.style.color = color;
        item.style.backgroundColor = '#fff';
      }
    } );

  } );

  /**
   * searchProduct Prevent Default Focus
   */
  let searchProduct = document.querySelector( '#searchProduct' );

  searchProduct?.addEventListener( 'focus', ( e ) => {
    e.preventDefault();
  } );

  let doubleScrollMain  = document.querySelector( '#double-scroll-main' ),
      doubleScrollFancy = document.querySelector( '#double-scroll-fancy' );

  if ( doubleScrollMain ) {

    doubleScrollFancy.querySelector( '.wrapper' ).style.width = doubleScrollMain.offsetWidth + 'px';

    doubleScrollMain.closest( 'div' ).addEventListener( 'scroll', ( e ) => {
      doubleScrollFancy.scrollLeft = e.target.scrollLeft;
    } );

    doubleScrollFancy.addEventListener( 'scroll', ( e ) => {
      doubleScrollMain.closest( 'div' ).scrollLeft = e.target.scrollLeft;
    } );
  }

  /**
   * Scroll horizontal to element .categoriesWrapper
   */
  let categoriesWrapper = document.querySelector( '.categoriesWrapper' );

  categoriesWrapper?.addEventListener( 'wheel', ( e ) => {
    e.preventDefault();
    categoriesWrapper.scrollLeft = categoriesWrapper.scrollLeft + (
      e.deltaY * 1.5
    );
  } );

  let dataSizes = `Ancho: ${window.innerWidth}px - Alto: ${window.innerHeight}px`;

  // alert( dataSizes );

  let selectSizeAuto = document.querySelectorAll( '.select-size-auto' );

  if ( selectSizeAuto ) {
    selectSizeAuto.forEach( ( el ) => {
      el.addEventListener( 'focus', ( e ) => {
        e.target.size = 5;
      } );
      el.addEventListener( 'blur', ( e ) => {
        e.target.size = 1;
      } );
      el.addEventListener( 'change', ( e ) => {
        e.target.size = 1;
        e.target.blur();
      } );
    } );
  }

} );
