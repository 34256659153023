import { isObject } from './isObject';
import { has } from './has';

export const extendsObj = (obj, ...args) => {
  args.forEach(arg => {
    for (const key in arg) {
      if (has(arg, key)) {
        if (isObject(arg[key]) && isObject(obj[key])) {
          extendsObj(obj[key], arg[key]);
        } else {
          obj[key] = arg[key];
        }
      }
    }
  });
  return obj;
}